import './App.css';
import React, { useEffect, useRef, useState } from "react";
//Firebase JS 
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

//react-firebase-hooks
import {useAuthState} from "react-firebase-hooks/auth";
import MenuIcon from '@material-ui/icons/Menu';
import {useCollectionData} from "react-firebase-hooks/firestore";


firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
});

const auth = firebase.auth();
const firestore = firebase.firestore();

function Login(){

  const signInWithGoogle = () =>{ 
  const provider = new firebase.auth.GoogleAuthProvider();
  auth.signInWithPopup(provider);
  }
  return(
    <button onClick = {signInWithGoogle} className = "sign-in-button">Sign In With Google</button>  
  )
}

function Logout(){

  return auth.currentUser && (
    <button onClick = {() => auth.signOut()} className = "logout-button"> Sign Out</button>
  )
}

function ChatRoom(props){
  const [text, setText] = useState('');
  const dummyRef = useRef();
  const messageRef = firestore.collection('Rooms').doc(props.code).collection("Messages");
  const query = messageRef.orderBy("createdAt");

  const [messages] = useCollectionData(query ,{idField: 'id'});

  useEffect(() => {
    dummyRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages])

  const sendMessage = async(e) => {
    e.preventDefault();

    const {uid, photoURL} = auth.currentUser;

    await messageRef.add({
      text: text,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid, 
      photoURL,
    })
    setText('');
  }

  return (
    <div className = "room-container">
      <div className = "messages">
      {messages && messages.map(msg => <ChatMessage key = {msg.id} message = {msg} />)}

      </div>
      <span ref = {dummyRef}></span>
      <form onSubmit = {sendMessage} className = "input-form">
        
        <input value = {text} onChange = {(e) => setText(e.target.value)} className = "message-input" placeholder = "Enter Message" />

        <button type = "submit" disabled = {!text} className = "send-msg">Send</button>
      </form>
    </div>
  )

  }

  function ChatMessage(props){
    const {text, uid, photoURL} = props.message;

    const messageClass = uid === auth.currentUser.uid ? 'send' : 'received';

    return(
      <div className = {`message ${messageClass}`}>
        <img src = {photoURL} alt = {"userImg"}/>
        <div>{text}</div>
      </div>
    )
  }

function CreateChatRoom (){

  const [roomId, setRoomId] = useState('');
  
  const createRoom = async (e) => {
    e.preventDefault();
    const db = firestore.collection("Rooms");
    db.doc(roomId).set({

    });
    setRoomId('');
  }

  return(
    <div className = "create-wrapper">
      <form onSubmit = {createRoom} className = "createRoomForm">
      <input value = {roomId} onChange = {e => setRoomId(e.target.value)} className = "createRoomInput" placeholder = "Create a room by entering a special code" />
      <button type = "submit" className = "createRoomButton">Create </button>
      </form>
    </div>
  );
}

function LandingPage(props){
  const [roomId, setRoomId] = useState('');
  const [showRoomId, setShowRoomId] = useState('');
  const checkFirstTime = async () => {
    const userCollection = firestore.collection("Users");
    const userRef = firestore.collection("Users").doc(auth.currentUser.uid);
    const doc = await userRef.get();
    if (!doc.exists){
      userCollection.doc(auth.currentUser.uid).set({
      })
    }
  }
  useEffect(() => {
    const fetch = async () => {
      await checkFirstTime();
    }
    fetch();
  }, []);

    const userCollection = firestore.collection("Users").doc(auth.currentUser.uid).collection("Rooms");
    const query = userCollection.limit(24);
    const [rooms] = useCollectionData(query)

  const handleRoomId = async (e) => {
    e.preventDefault();
      const userRef = firestore.collection("Users").doc(auth.currentUser.uid).collection("Rooms");
      const query = await userRef.where("roomID", "==", roomId).get();
      if (query.empty){
        userRef.add({
          roomID: roomId
        });
      };
    setRoomId('');
}
  
  return(
    <div className = "room-wrapper">
    <div className = {props.nav ? "room-mobile" : "room-navbar"}>
    {rooms && rooms.map((room, i) => {
      return(
        <div className = "room-id" onClick = {() => {
        setShowRoomId(room.roomID)
        if (props.nav){
          props.toggle();
        }
        }} key = {i}>
          {room.roomID}
        </div>
      );
    })}
      <form onSubmit = {handleRoomId} className = "join-form">
          <input value = {roomId} onChange = {e => setRoomId(e.target.value)} className = "join-input" placeholder = "Enter a code to join" />
          <button className = "join-button" type = "submit">Join</button>
      </form> 
      </div>
      <div className = "chat-main">
      {showRoomId !== '' ? <div className = "chat-wrapper"><ChatRoom code = {showRoomId} /></div>  : <div className = "message-placeholder"> Create a Room or Join one </div>}
      </div>
    </div>

  );
}

function App() {

  const [user] = useAuthState(auth);
  const [showNav, setShowNav] = useState(false);
  const toggleNav = () => {
    setShowNav(!showNav);
  }

  return (
    <div className="App">
      <div className = "main-wrapper">
      {user ? <div className = "header"> 
      <div className = "nav" onClick = {toggleNav}>
      <MenuIcon />
      </div>
<div className = "header-text">Welcome</div><CreateChatRoom /> {user ? <Logout /> : <> </>}</div>:  <></>}
      {user ?
        <LandingPage nav = {showNav} toggle = {toggleNav}/>
       : <Login />}
      </div>
    </div>
  );
}

export default App;
